'use strict';

function _asyncGeneratorDelegate(inner, awaitWrap) { var iter = {}, waiting = false; function pump(key, value) { waiting = true; value = new Promise(function (resolve) { resolve(inner[key](value)); }); return { done: false, value: awaitWrap(value) }; } ; if (typeof Symbol === "function" && Symbol.iterator) { iter[Symbol.iterator] = function () { return this; }; } iter.next = function (value) { if (waiting) { waiting = false; return value; } return pump("next", value); }; if (typeof inner.throw === "function") { iter.throw = function (value) { if (waiting) { waiting = false; throw value; } return pump("throw", value); }; } if (typeof inner.return === "function") { iter.return = function (value) { if (waiting) { waiting = false; return value; } return pump("return", value); }; } return iter; }

function _asyncIterator(iterable) { var method; if (typeof Symbol !== "undefined") { if (Symbol.asyncIterator) { method = iterable[Symbol.asyncIterator]; if (method != null) return method.call(iterable); } if (Symbol.iterator) { method = iterable[Symbol.iterator]; if (method != null) return method.call(iterable); } } throw new TypeError("Object is not async iterable"); }

function _awaitAsyncGenerator(value) { return new _AwaitValue(value); }

function _wrapAsyncGenerator(fn) { return function () { return new _AsyncGenerator(fn.apply(this, arguments)); }; }

function _AsyncGenerator(gen) { var front, back; function send(key, arg) { return new Promise(function (resolve, reject) { var request = { key: key, arg: arg, resolve: resolve, reject: reject, next: null }; if (back) { back = back.next = request; } else { front = back = request; resume(key, arg); } }); } function resume(key, arg) { try { var result = gen[key](arg); var value = result.value; var wrappedAwait = value instanceof _AwaitValue; Promise.resolve(wrappedAwait ? value.wrapped : value).then(function (arg) { if (wrappedAwait) { resume(key === "return" ? "return" : "next", arg); return; } settle(result.done ? "return" : "normal", arg); }, function (err) { resume("throw", err); }); } catch (err) { settle("throw", err); } } function settle(type, value) { switch (type) { case "return": front.resolve({ value: value, done: true }); break; case "throw": front.reject(value); break; default: front.resolve({ value: value, done: false }); break; } front = front.next; if (front) { resume(front.key, front.arg); } else { back = null; } } this._invoke = send; if (typeof gen.return !== "function") { this.return = undefined; } }

if (typeof Symbol === "function" && Symbol.asyncIterator) { _AsyncGenerator.prototype[Symbol.asyncIterator] = function () { return this; }; }

_AsyncGenerator.prototype.next = function (arg) { return this._invoke("next", arg); };

_AsyncGenerator.prototype.throw = function (arg) { return this._invoke("throw", arg); };

_AsyncGenerator.prototype.return = function (arg) { return this._invoke("return", arg); };

function _AwaitValue(value) { this.wrapped = value; }

const errCode = require('err-code');

const {
  Buffer
} = require('buffer');

const globalThis = require('ipfs-utils/src/globalthis');
/*
 * Transform one of:
 *
 * ```
 * Bytes (Buffer|ArrayBuffer|TypedArray) [single file]
 * Bloby (Blob|File) [single file]
 * String [single file]
 * { path, content: Bytes } [single file]
 * { path, content: Bloby } [single file]
 * { path, content: String } [single file]
 * { path, content: Iterable<Number> } [single file]
 * { path, content: Iterable<Bytes> } [single file]
 * { path, content: AsyncIterable<Bytes> } [single file]
 * Iterable<Number> [single file]
 * Iterable<Bytes> [single file]
 * Iterable<Bloby> [multiple files]
 * Iterable<String> [multiple files]
 * Iterable<{ path, content: Bytes }> [multiple files]
 * Iterable<{ path, content: Bloby }> [multiple files]
 * Iterable<{ path, content: String }> [multiple files]
 * Iterable<{ path, content: Iterable<Number> }> [multiple files]
 * Iterable<{ path, content: Iterable<Bytes> }> [multiple files]
 * Iterable<{ path, content: AsyncIterable<Bytes> }> [multiple files]
 * AsyncIterable<Bytes> [single file]
 * AsyncIterable<Bloby> [multiple files]
 * AsyncIterable<String> [multiple files]
 * AsyncIterable<{ path, content: Bytes }> [multiple files]
 * AsyncIterable<{ path, content: Bloby }> [multiple files]
 * AsyncIterable<{ path, content: String }> [multiple files]
 * AsyncIterable<{ path, content: Iterable<Number> }> [multiple files]
 * AsyncIterable<{ path, content: Iterable<Bytes> }> [multiple files]
 * AsyncIterable<{ path, content: AsyncIterable<Bytes> }> [multiple files]
 * ```
 * Into:
 *
 * ```
 * AsyncIterable<{ path, content: AsyncIterable<Buffer> }>
 * ```
 *
 * @param input Object
 * @return AsyncInterable<{ path, content: AsyncIterable<Buffer> }>
 */


module.exports = function normaliseInput(input) {
  // must give us something
  if (input === null || input === undefined) {
    throw errCode(new Error("Unexpected input: ".concat(input), 'ERR_UNEXPECTED_INPUT'));
  } // String


  if (typeof input === 'string' || input instanceof String) {
    return _wrapAsyncGenerator(function* () {
      // eslint-disable-line require-await
      yield toFileObject(input);
    })();
  } // Buffer|ArrayBuffer|TypedArray
  // Blob|File


  if (isBytes(input) || isBloby(input)) {
    return _wrapAsyncGenerator(function* () {
      // eslint-disable-line require-await
      yield toFileObject(input);
    })();
  } // Iterable<?>


  if (input[Symbol.iterator]) {
    return _wrapAsyncGenerator(function* () {
      // eslint-disable-line require-await
      const iterator = input[Symbol.iterator]();
      const first = iterator.next();
      if (first.done) return iterator; // Iterable<Number>
      // Iterable<Bytes>

      if (Number.isInteger(first.value) || isBytes(first.value)) {
        yield toFileObject(function* () {
          yield first.value;
          yield* iterator;
        }());
        return;
      } // Iterable<Bloby>
      // Iterable<String>
      // Iterable<{ path, content }>


      if (isFileObject(first.value) || isBloby(first.value) || typeof first.value === 'string') {
        yield toFileObject(first.value);

        for (const obj of iterator) {
          yield toFileObject(obj);
        }

        return;
      }

      throw errCode(new Error('Unexpected input: ' + typeof input), 'ERR_UNEXPECTED_INPUT');
    })();
  } // window.ReadableStream


  if (typeof input.getReader === 'function') {
    return _wrapAsyncGenerator(function* () {
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;

      var _iteratorError;

      try {
        for (var _iterator = _asyncIterator(browserStreamToIt(input)), _step, _value; _step = yield _awaitAsyncGenerator(_iterator.next()), _iteratorNormalCompletion = _step.done, _value = yield _awaitAsyncGenerator(_step.value), !_iteratorNormalCompletion; _iteratorNormalCompletion = true) {
          const obj = _value;
          yield toFileObject(obj);
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return != null) {
            yield _awaitAsyncGenerator(_iterator.return());
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }
    })();
  } // AsyncIterable<?>


  if (input[Symbol.asyncIterator]) {
    return _wrapAsyncGenerator(function* () {
      const iterator = input[Symbol.asyncIterator]();
      const first = yield _awaitAsyncGenerator(iterator.next());
      if (first.done) return iterator; // AsyncIterable<Bytes>

      if (isBytes(first.value)) {
        yield toFileObject(_wrapAsyncGenerator(function* () {
          // eslint-disable-line require-await
          yield first.value;
          yield* _asyncGeneratorDelegate(_asyncIterator(iterator), _awaitAsyncGenerator);
        })());
        return;
      } // AsyncIterable<Bloby>
      // AsyncIterable<String>
      // AsyncIterable<{ path, content }>


      if (isFileObject(first.value) || isBloby(first.value) || typeof first.value === 'string') {
        yield toFileObject(first.value);
        var _iteratorNormalCompletion2 = true;
        var _didIteratorError2 = false;

        var _iteratorError2;

        try {
          for (var _iterator2 = _asyncIterator(iterator), _step2, _value2; _step2 = yield _awaitAsyncGenerator(_iterator2.next()), _iteratorNormalCompletion2 = _step2.done, _value2 = yield _awaitAsyncGenerator(_step2.value), !_iteratorNormalCompletion2; _iteratorNormalCompletion2 = true) {
            const obj = _value2;
            yield toFileObject(obj);
          }
        } catch (err) {
          _didIteratorError2 = true;
          _iteratorError2 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion2 && _iterator2.return != null) {
              yield _awaitAsyncGenerator(_iterator2.return());
            }
          } finally {
            if (_didIteratorError2) {
              throw _iteratorError2;
            }
          }
        }

        return;
      }

      throw errCode(new Error('Unexpected input: ' + typeof input), 'ERR_UNEXPECTED_INPUT');
    })();
  } // { path, content: ? }
  // Note: Detected _after_ AsyncIterable<?> because Node.js streams have a
  // `path` property that passes this check.


  if (isFileObject(input)) {
    return _wrapAsyncGenerator(function* () {
      // eslint-disable-line require-await
      yield toFileObject(input);
    })();
  }

  throw errCode(new Error('Unexpected input: ' + typeof input), 'ERR_UNEXPECTED_INPUT');
};

function toFileObject(input) {
  const obj = {
    path: input.path || '',
    mode: input.mode,
    mtime: input.mtime
  };

  if (input.content) {
    obj.content = toAsyncIterable(input.content);
  } else if (!input.path) {
    // Not already a file object with path or content prop
    obj.content = toAsyncIterable(input);
  }

  return obj;
}

function toAsyncIterable(input) {
  // Bytes | String
  if (isBytes(input) || typeof input === 'string') {
    return _wrapAsyncGenerator(function* () {
      // eslint-disable-line require-await
      yield toBuffer(input);
    })();
  } // Bloby


  if (isBloby(input)) {
    return blobToAsyncGenerator(input);
  } // Browser stream


  if (typeof input.getReader === 'function') {
    return browserStreamToIt(input);
  } // Iterator<?>


  if (input[Symbol.iterator]) {
    return _wrapAsyncGenerator(function* () {
      // eslint-disable-line require-await
      const iterator = input[Symbol.iterator]();
      const first = iterator.next();
      if (first.done) return iterator; // Iterable<Number>

      if (Number.isInteger(first.value)) {
        yield toBuffer(Array.from(function* () {
          yield first.value;
          yield* iterator;
        }()));
        return;
      } // Iterable<Bytes>


      if (isBytes(first.value)) {
        yield toBuffer(first.value);

        for (const chunk of iterator) {
          yield toBuffer(chunk);
        }

        return;
      }

      throw errCode(new Error('Unexpected input: ' + typeof input), 'ERR_UNEXPECTED_INPUT');
    })();
  } // AsyncIterable<Bytes>


  if (input[Symbol.asyncIterator]) {
    return _wrapAsyncGenerator(function* () {
      var _iteratorNormalCompletion3 = true;
      var _didIteratorError3 = false;

      var _iteratorError3;

      try {
        for (var _iterator3 = _asyncIterator(input), _step3, _value3; _step3 = yield _awaitAsyncGenerator(_iterator3.next()), _iteratorNormalCompletion3 = _step3.done, _value3 = yield _awaitAsyncGenerator(_step3.value), !_iteratorNormalCompletion3; _iteratorNormalCompletion3 = true) {
          const chunk = _value3;
          yield toBuffer(chunk);
        }
      } catch (err) {
        _didIteratorError3 = true;
        _iteratorError3 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion3 && _iterator3.return != null) {
            yield _awaitAsyncGenerator(_iterator3.return());
          }
        } finally {
          if (_didIteratorError3) {
            throw _iteratorError3;
          }
        }
      }
    })();
  }

  throw errCode(new Error("Unexpected input: ".concat(input)), 'ERR_UNEXPECTED_INPUT');
}

function toBuffer(chunk) {
  return isBytes(chunk) ? chunk : Buffer.from(chunk);
}

function isBytes(obj) {
  return Buffer.isBuffer(obj) || ArrayBuffer.isView(obj) || obj instanceof ArrayBuffer;
}

function isBloby(obj) {
  return typeof globalThis.Blob !== 'undefined' && obj instanceof globalThis.Blob;
} // An object with a path or content property


function isFileObject(obj) {
  return typeof obj === 'object' && (obj.path || obj.content);
}

function blobToAsyncGenerator(blob) {
  if (typeof blob.stream === 'function') {
    // firefox < 69 does not support blob.stream()
    return browserStreamToIt(blob.stream());
  }

  return readBlob(blob);
}

function browserStreamToIt(_x) {
  return _browserStreamToIt.apply(this, arguments);
}

function _browserStreamToIt() {
  _browserStreamToIt = _wrapAsyncGenerator(function* (stream) {
    const reader = stream.getReader();

    while (true) {
      const result = yield _awaitAsyncGenerator(reader.read());

      if (result.done) {
        return;
      }

      yield result.value;
    }
  });
  return _browserStreamToIt.apply(this, arguments);
}

function readBlob(_x2, _x3) {
  return _readBlob.apply(this, arguments);
}

function _readBlob() {
  _readBlob = _wrapAsyncGenerator(function* (blob, options) {
    options = options || {};
    const reader = new globalThis.FileReader();
    const chunkSize = options.chunkSize || 1024 * 1024;
    let offset = options.offset || 0;

    const getNextChunk = () => new Promise((resolve, reject) => {
      reader.onloadend = e => {
        const data = e.target.result;
        resolve(data.byteLength === 0 ? null : data);
      };

      reader.onerror = reject;
      const end = offset + chunkSize;
      const slice = blob.slice(offset, end);
      reader.readAsArrayBuffer(slice);
      offset = end;
    });

    while (true) {
      const data = yield _awaitAsyncGenerator(getNextChunk());

      if (data == null) {
        return;
      }

      yield Buffer.from(data);
    }
  });
  return _readBlob.apply(this, arguments);
}